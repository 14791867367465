<template>
  <div
    :class="[
      'w-full h-full group/status',
      { '!cursor-not-allowed': disabled },
    ]"
  >
    <TaskAssigneePopover
      ref="assigneeRef"
      class="w-full h-full"
      :current-assignee="task?.assignee"
      :board-id="task.boardId"
      :disabled="disabled"
      @update:open="onUpdateOpen"
      @select="handleSelect"
      @remove="handleRemove"
    >
      <div :class="['w-full h-full flex items-center min-h-8', { 'active': isPopoverOpen }, cellClass]">
        <template v-if="task.assignee">
          <UButton
            variant="soft"
            color="white"
            class="text-gray-900 w-full h-full font-normal"
            size="2xs"
            data-test="assignee-btn"
            :disabled="disabled"
            @click="emit('click')"
          >
            <template #leading>
              <Avatar :id="task.assignee.id" :src="task.assignee.photo" :name="task.assignee.fullName" size="2xs" />
            </template>
            <Tooltip :text="task.assignee.fullName">
              <template #default="{ getTextRef }">
                <span :ref="getTextRef" class="line-clamp-1 break-all text-start">{{ task.assignee.fullName }}</span>
              </template>
            </Tooltip>
          </UButton>
        </template>
        <template v-else>
          <UButton
            variant="soft"
            size="2xs"
            color="white"
            class="text-gray-400 w-full h-full"
            :disabled="disabled"
            @click="emit('click')"
          >
            <Icon :size="16" name="heroicons:user-circle" class="ml-0.5" />
          </UButton>
        </template>
      </div>
    </TaskAssigneePopover>
  </div>
</template>

<script lang="ts" setup>
import type { TaskDetail } from '#task/types'
import type { FieldCallback } from '#field/types'

const props = defineProps({
  task: {
    type: Object as PropType<TaskDetail>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  cellClass: {
    type: String,
    default: '',
  },
})
const emit = defineEmits<{
  (e: 'set-callback', callback: Partial<FieldCallback>): void
  (e: 'click'): void
  (e: 'update:open', value: boolean): void
}>()

const { selectedTaskIds } = useListViewSharedState()
const { updateTask, updateTasks } = useUpdateTask()

const assigneeRef = ref()
const isPopoverOpen = ref(false)

const handleSelect = (assignee: string) => {
  if (selectedTaskIds.value.has(props.task.id)) {
    return updateTasks(Array.from(selectedTaskIds.value), { assignee })
  }

  updateTask(props.task.id, { assignee })
}

const handleRemove = () => {
  if (selectedTaskIds.value.has(props.task.id)) {
    return updateTasks(Array.from(selectedTaskIds.value), { assignee: null })
  }

  updateTask(props.task.id, { assignee: null })
}

const handleOpen = () => {
  assigneeRef.value?.open()
}

const onUpdateOpen = (open: boolean) => {
  isPopoverOpen.value = open
  emit('update:open', open)
}

onMounted(() => {
  emit('set-callback', {
    open: handleOpen,
    delete: handleRemove
  })
})
</script>
